import {
  useCallback, useEffect, useState,
} from 'react';
import { CSVLink } from 'react-csv';

import { useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { isClimateSurvey } from '../../../../utils';

import ORGANIZATION_ASSESSMENTS_QUERY from '../../../../graphql/queries/organization/assessments';
import ORGANIZATION_USERS_QUERY from '../../../../graphql/queries/components/pages/Dashboard/Student';

import Checkbox from '../../../Checkbox';
import LoadingPane from '../../../LoadingPane';
import TableView from '../../../TableView';
import SearchField from '../../../SearchField';
import SummaryTooltip from './Tooltip';

// TODO: see Classroom.jsx for refactor for fewer rerenders

const SummaryStudent = (props) => {
  const {
    assessment,
    district,
    school,
    classroom,
    stickyNode,
    schools,
    setParentTimepoint,
    setInSummaryPlot,
    searchQueryString,
  } = props;
  const [ onload, setOnload ] = useState(true);
  const [ isRequest, setIsRequest ] = useState(true);
  const [ time, setTime ] = useState();
  const [ search, setSearch ] = useState();
  const [ students, setStudents ] = useState([]);
  const [ filteredStudents, setFilteredStudents ] = useState([]);
  const [ assessments, setAssessments ] = useState([]);
  const [ categories, setCategories ] = useState([]);
  const [ csvData, setCsvData ] = useState([]);

  const navigate = useNavigate();
  const [ sortKey, setSortKey ] = useState('fullName');
  const [ sortDirection, setSortDirection ] = useState('asc');
  const [ filterList, setFilterList ] = useState([]);
  const [ studentBaseList, setStudentBaseList ] = useState([]);
  const [ gradesArray, setGradesArray ] = useState([]);
  // const [ classroomsArray, setClassroomsArray ] = useState([]);
  // const [ schoolsArray, setSchoolsArray ] = useState([]);
  const orgId = school ? school.id : district.id;

  // placeholder for schoolYears
  const [ schoolYear, setSchoolYear ] = useState();

  const {
    data, loading, refetch: refetchOrgAssessments,
  } = useQuery(ORGANIZATION_ASSESSMENTS_QUERY, { variables: { orgIDs: school ? [ school.id ] : schools.map((org) => org.id) } });
  const { refetch } = useQuery(ORGANIZATION_USERS_QUERY, {
    variables: {
      orgIDs: school ? [ school.id ] : schools.map((org) => org.id),
      classIDs: classroom ? [ classroom?.id ] : [],
      assessmentID: assessment?.id,
    },
  });

  const fetchStudents = useCallback(async (arr) => {
    const globalAssessment = arr.find((o) => o.name === assessment.name);
    const orgAssessments = arr.filter((o) => o.assessment_id === assessment?.id);
    const response = await refetch({
      orgIDs: school ? [ school.id ] : schools.map((org) => org.id),
      classIDs: classroom ? [ classroom?.id ] : undefined,
      assessmentId: globalAssessment?.id,
    });

    if (response.error) window.alert(response.error);

    setAssessments(orgAssessments);

    if (orgAssessments.length > 0) {
      const tempTime = orgAssessments[0].timepoint; // preset selection to first assessment
      setTime(tempTime);
      setInSummaryPlot(false);
      setParentTimepoint(tempTime);
      setSchoolYear(orgAssessments[0].school_year);
    }

    setStudents(response.data.getUsers || []);

    if (assessment?.categories) {
      setCategories(assessment.categories.split(',').map((category) => {
        const strParts = category.split('|');

        return {
          key: strParts[0],
          name: strParts[1] ?? strParts[0],
        };
      }));
    }

    setOnload(false);
    setIsRequest(false);
  }, [
    assessment,
    district,
    school,
    classroom,
  ]);

  // onload
  useEffect(() => {
    if (onload && !loading) {
      if (data) fetchStudents(data?.getOrgAssessments || []);
    }
  }, [
    onload,
    loading,
    data,
    fetchStudents,
  ]);

  // handles any update on district or school selection
  useEffect(() => {
    setIsRequest(true);
    const schoolIds = school ? [ school.id ] : schools.map((org) => org.id);

    refetchOrgAssessments({ orgIds: schoolIds }).then((response) => {
      fetchStudents(response?.data?.getOrgAssessments || []);
    });
    setSearch('');
    setSortKey('name');
    setSortDirection('asc');
    setFilterList([]);
  }, [
    orgId,
    district,
    schools,
    fetchStudents,
    refetchOrgAssessments,
    assessment,
    school,
    classroom,
  ]);

  // handles any assessment time selection filtering
  useEffect(() => {
    const assessmentIds = assessments.filter((a) => a.timepoint === time).map((a) => parseInt(a.id)); // org assessment ids that correlate to the timepoint
    const arr = students.filter((student) => {
      const scores = student.scores ? student.scores.filter((score) => assessmentIds.includes(score.orgAssessmentID)) : [];

      return (scores.length > 0) ? student : null;
    });

    const usersWithScores = arr.map((o) => {
      const obj = {
        ...o,
        fullName: `${o.first_name} ${o.last_name}`,
        class: _.map(o.classes, 'name').join(', '),
      };

      o.scores.filter((score) => assessmentIds.includes(score.orgAssessmentID)).forEach((score) => {
        obj[score.category] = score.score || 0;
      });

      return obj;
    });
    const sortedStudents = _.sortBy(usersWithScores, [ 'first_name', 'last_name' ]);
    const csvHeaders = [
      'First Name',
      'Last Name',
      'School',
      'Class',
      'Grade',
      ...categories.map((c) => c.name),
    ];
    const csvStudents = sortedStudents.map((user) => {
      const scores = [];

      categories.forEach((c) => {
        const score = user.scores.find((s) => s.category === c.key);

        if (!score) {
          scores.push(0);
        } else {
          scores.push(score.score);
        }
      });

      return [
        user.first_name,
        user.last_name,
        user.grade,
        user?.school,
        user?.class?.name,
        ...scores,
      ];
    });

    setCsvData([ csvHeaders, ...csvStudents ]);
    setFilteredStudents(_.sortBy(usersWithScores, [ 'first_name', 'last_name' ]));
    setStudentBaseList(_.sortBy(usersWithScores, [ 'first_name', 'last_name' ]));
  }, [ time, students ]);

  const handleSort = (key) => {
    if (sortKey === key) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortKey(key);
      setSortDirection('asc');
    }
  };

  // toggling sort column
  useEffect(() => {
    const sortedStudentData = _.orderBy(filteredStudents, sortKey, sortDirection);
    setFilteredStudents(sortedStudentData);
  }, [ sortKey, sortDirection ]);
  //

  // set up filtering arrays
  useEffect(() => {
    // TODO: with API change prob need to refactor this
    const assessmentIds = assessments.filter((a) => a.timepoint === time).map((a) => parseInt(a.id)); // org assessment ids that correlate to the timepoint
    const arr = students.filter((student) => {
      const scores = student.scores ? student.scores.filter((score) => assessmentIds.includes(score.orgAssessmentID)) : [];

      return (scores.length > 0) ? student : null;
    });
    const uniqueGrades = _.sortBy([ ...new Set(arr.map((student) => student.grade)) ], (grade) => parseInt(grade));
    // const uniqueClassrooms = _.sortBy([ ...new Set(arr.flatMap((student) => _.map(student.classes, 'name'))) ]);
    // const uniqueSchools = _.sortBy([ ...new Set(arr.map((student) => student.school)) ]);

    setGradesArray(uniqueGrades);
    // setClassroomsArray(uniqueClassrooms);
    // setSchoolsArray(uniqueSchools);
  }, [
    students,
    time,
    assessments,
  ]);

  const handleCheckboxChange = (value) => {
    if (filterList.includes(value)) {
      setFilterList(filterList.filter((filter) => filter !== value));
    } else {
      setFilterList([ ...filterList, value ]);
    }
  };

  // toggling filter keys
  useEffect(() => {
    const isElem = [
      '1',
      '2',
      '3',
    ].includes(assessment.id);
    const isClimate = isClimateSurvey(assessment);
    const isHighSchool = [
      '5',
      '84',
      '85',
    ].includes(assessment.id);
    const scoreBandsHashElem = {
      aboveAvg: {
        min: 115,
        max: Infinity,
      },
      avg: {
        min: 90,
        max: 115,
      },
      belowAvg: {
        min: 70,
        max: 90,
      },
      wellBelowAvg: {
        min: 0,
        max: 70,
      },
    };
    const scoreBandsHashClimate = {
      agreeALot: {
        min: 4,
        max: Infinity,
      },
      agreeALittle: {
        min: 3,
        max: 4,
      },
      disagreeALittle: {
        min: 2,
        max: 3,
      },
      disagreeALot: {
        min: 1,
        max: 2,
      },
    };
    const scoreBandHashHighSchool = {
      highlyConfident: {
        min: 5,
        max: Infinity,
      },
      confident: {
        min: 4,
        max: 5,
      },
      sometimesConfident: {
        min: 3,
        max: 4,
      },
      notVeryConfident: {
        min: 2,
        max: 3,
      },
      notConfident: {
        min: 1,
        max: 2,
      },
    };
    const groupingsFilterHash = {};
    filterList.forEach((filter) => {
      const filterSplit = filter.split('-');
      const grouping = filterSplit[0];
      const scoreBand = filterSplit[1];
      if (!groupingsFilterHash[grouping]) {
        groupingsFilterHash[grouping] = [ scoreBand ];
      } else {
        groupingsFilterHash[grouping].push(scoreBand);
      }
    });
    let tempFilteredStudents = (search) ? studentBaseList.filter((student) => student.fullName.toLowerCase().includes(search.toLowerCase())) : studentBaseList;
    for (const grouping in groupingsFilterHash) {
      tempFilteredStudents = tempFilteredStudents.filter((studentObj) => {
        const nonScoredCategories = [
          'grade',
          'class',
          'school',
        ];
        const notScored = nonScoredCategories.includes(grouping);
        return groupingsFilterHash[grouping].some((scoreBand) => {
          if (!notScored) {
            const scoreBandsHash = (isElem) ? scoreBandsHashElem : (isClimate) ? scoreBandsHashClimate : (isHighSchool) ? scoreBandHashHighSchool : {};
            const minScore = scoreBandsHash[scoreBand]?.min || 0;
            const maxScore = scoreBandsHash[scoreBand]?.max || Infinity;
            const schoolScore = studentObj[grouping];
            return schoolScore >= minScore && schoolScore < maxScore;
          } else {
            if (grouping === 'class') return studentObj.class === scoreBand;
            return studentObj[grouping] === scoreBand;
          }
        });
      });
    };
    tempFilteredStudents = _.orderBy(tempFilteredStudents, sortKey, sortDirection);
    setFilteredStudents(tempFilteredStudents);
  }, [
    filterList,
    sortKey,
    sortDirection,
    studentBaseList,
    search,
    assessment,
  ]);

  function generateTimepoints () {
    const arr = [];
    const max = Math.max(...assessments.map((a) => a.timepoint));
    let idx = 1;

    while (idx <= max) {
      arr.push(idx);
      idx++;
    }
    return arr;
  }

  if (onload) return <LoadingPane />;

  return (
    <div className='summary-student-view'>
      <ul className='times-frame'>
        {generateTimepoints().map((timepoint) => {
          const selected = (time === timepoint);

          return (
            <Checkbox
              key={timepoint}
              onChange={() => {
                setTime(timepoint);
                setInSummaryPlot(false);
                setParentTimepoint(timepoint);
              }}
              checked={selected}
              title={`Time ${timepoint}`}
              type='radio'
            />
          );
        })}
      </ul>

      {isRequest
        ? <LoadingPane />
        : <div className='table-frame'>
          <div className='table-toolbar-frame'>
            <SearchField
              placeholder='First Name, School, etc...'
              onChange={(e) => setSearch(e.target.value)}
              value={search}
            />

            {filterList.length > 0 &&
            <button
            className='clear-all-filters-btn'
            onClick={() => setFilterList([])}>
            CLEAR FILTERS</button>}

            <CSVLink
              className='table-toolbar-button'
              data={csvData}
              filename={`${assessment.name.toUpperCase()} ${(school ? school?.name : district?.name).toUpperCase()} ${new Date().toLocaleDateString('en-US')}`.replace(/ /g, '-')}
            >export report</CSVLink>
          </div>

          <TableView
            headers={[
              {
                label: 'Name',
                key: 'fullName',
              },
              {
                label: 'Grade',
                key: 'grade',
              },
              {
                label: 'School',
                key: 'school',
              },
              {
                label: 'Classroom',
                key: 'class',
              },
              ...categories.map((c) => ({
                label: c.name,
                key: c.key,
              })),
            ]}
            assessment={assessment}
            data={filteredStudents}
            dataMappings={[
              { key: 'fullName' },
              { key: 'grade' },
              { key: 'school' },
              { key: 'class' },
              ...categories.map((c) => ({
                key: c.key,
                score: c.key,
              })),
            ]}
            stickyNode={stickyNode}
            onClick={(obj) => {
              // year will go here eventually
              navigate(`/users/${obj.id}/assessment/${assessment.id}/schoolYear/${schoolYear}/timepoint/${time}`, { state: { searchQueryString } });
            }}
            sortKey={sortKey}
            sortDirection={sortDirection}
            onSort={handleSort}
            onFilter={handleCheckboxChange}
            gradesArray={gradesArray}
            // classroomsArray={classroomsArray}
            // schoolsArray={schoolsArray}
            filterList={filterList}
          />

          <SummaryTooltip
          isClimate={isClimateSurvey(assessment)}
          isHighSchool={[
            '5',
            '84',
            '85',
          ].includes(assessment.id)}/>
        </div>
      }
    </div>
  );
};

SummaryStudent.propTypes = {
  assessment: PropTypes.object,
  district: PropTypes.object,
  school: PropTypes.object,
  stickyNode: PropTypes.object,
  classroom: PropTypes.object,
  schools: PropTypes.array,
  setParentTimepoint: PropTypes.func,
  setInSummaryPlot: PropTypes.func,
  searchQueryString: PropTypes.string,
};

export default SummaryStudent;
